import React, {
  useEffect,
  useContext,
  FocusEventHandler,
  ChangeEventHandler,
  useState,
  useMemo,
} from 'react'
import { IDriver, KeyValuePairOfType } from '../../interfaces/Registration'
import {
  ADULT_FIRST_NAME_LABEL,
  ADULT_LAST_NAME_LABEL,
  ADULT_DATE_OF_BIRTH_LABEL,
  ADULT_DEFAULT_GENDER,
  ADULT_GENDER_MALE,
  ADULT_GENDER_FEMALE,
  ADULT_GENDER_NOTDISCLOSE,
  ADULT_GENDER_LABEL,
  ADULT_ADDRESS_LABEL,
  ADULT_MOBILE_PHONE_LABEL,
} from './Constants'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Capitalize from '../../utils/Capitalize'
import CustomDatepicker from './CustomDatepicker'

type Props = {
  driver: IDriver
  isSubmitDisable: boolean
  errors: any
  editMode: boolean
  handleDriverFieldChange: Function
  handleOnChangeInput: ChangeEventHandler<HTMLInputElement>
  handleBlurInput: Function
  handleOnChangeSelect: ChangeEventHandler<HTMLSelectElement>
  handleBlurSelect: FocusEventHandler<HTMLSelectElement>
  goToNextPage: Function
  backToDriverForm: Function
  addressRef: any
  firstNameRef: any
  lastNameRef: any
  phoneRef: any
  dobRef: any
  signRef: any
  handleSubmit: Function
  handleChangeDriverFields: (data: Partial<KeyValuePairOfType<IDriver>>) => void
}

const AdultDriverFormOnly = (props: Props) => {
  const {
    driver,
    errors,
    handleDriverFieldChange,
    handleBlurInput,
    handleOnChangeInput,
    handleOnChangeSelect,
    handleBlurSelect,
    addressRef,
    firstNameRef,
    lastNameRef,
    phoneRef,
    goToNextPage,
    backToDriverForm,
    handleChangeDriverFields,
  } = props
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { dispatch, email, selectedDOBError, selectedDOB } = registrationContext
  const [isAllInputsFilled, setIsAllInputsFilled] = useState<boolean>(false)
  const [addressInteracted, setAddressInteracted] = useState<boolean>(false)

  const blurInputHandler: FocusEventHandler<HTMLInputElement> = e => {
    handleBlurInput(e.target.name, false)
  }

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'au' },
    },
    debounce: 700,
  })

  useEffect(() => {
    setValue(driver.address, false)
  }, [driver.address, setValue])

  useEffect(() => {
    if (driver.email !== email) {
      handleDriverFieldChange({ email })
    }
  }, [driver.email, email, handleDriverFieldChange])

  const handleSelect = (value: any) => async () => {
    const { description, place_id: placeId } = value

    setValue(description, false)
    clearSuggestions()

    const results = (await getDetails({
      placeId,
    })) as google.maps.places.PlaceResult

    const country = results.address_components?.find((addressComponent: any) =>
      addressComponent.types.includes('country')
    )

    const zip_code = results.address_components?.find((addressComponent: any) =>
      addressComponent.types.includes('postal_code')
    )

    const city_id = 2284
    const city = 'Sydney'

    handleDriverFieldChange({
      address: description,
      country: country?.short_name,
      place_id: placeId,
      zip_code: zip_code?.long_name,
      city_id: city_id,
      city: city,
    })
  }

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <li
          className="border-b py-2.5 cursor-pointer last:border-0"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      )
    })

  useEffect(() => {
    const allInputsFilled =
      driver.first_name !== '' &&
      driver.last_name !== '' &&
      driver.gender !== '' &&
      driver.phone_number !== '' &&
      driver.address !== '' &&
      driver.city !== '' &&
      driver.zip_code !== '' &&
      selectedDOB.day !== '' &&
      selectedDOB.month !== '' &&
      selectedDOB.year !== ''

    const noErrors =
      !errors.first_name &&
      !errors.last_name &&
      !errors.gender &&
      !errors.phone_number &&
      !errors.address &&
      !selectedDOBError

    if (allInputsFilled && noErrors) {
      setIsAllInputsFilled(true)
    } else {
      setIsAllInputsFilled(false)
    }
  }, [
    driver.address,
    driver.city,
    driver.first_name,
    driver.gender,
    driver.last_name,
    driver.phone_number,
    driver.zip_code,
    errors.address,
    errors.first_name,
    errors.gender,
    errors.last_name,
    errors.phone_number,
    selectedDOB.day,
    selectedDOB.month,
    selectedDOB.year,
    selectedDOBError,
  ])

  const handleNextComponent = () => {
    dispatch({
      type: 'SET_REGISTRATION_DRIVER',
      payload: {
        driver: { ...driver, signature_url: '' } as IDriver,
      },
    })
    goToNextPage()
  }

  const capitalizeFirstName = useMemo(
    () => Capitalize(driver.first_name),
    [driver.first_name]
  )

  const capitalizeLastName = useMemo(
    () => Capitalize(driver.last_name),
    [driver.last_name]
  )

  return (
    <>
      <div className="sm:flex">
        <div className="mb-4 sm:mr-2.5 w-full">
          <span className="mb-2.5 text-base font-semibold flex text-[#2F1160]">
            {ADULT_FIRST_NAME_LABEL}
          </span>
          <input
            name="first_name"
            onChange={handleOnChangeInput}
            onBlur={blurInputHandler}
            value={capitalizeFirstName}
            type="text"
            placeholder="First Name"
            ref={firstNameRef}
            className={`outline-0 px-4 py-3 text-base border capitalize rounded-xl w-full ${
              errors.first_name && 'border-red-500'
            }`}
          />
          {<p className="text-red-500 text-sm">{errors.first_name}</p>}
        </div>
        <div className="mb-4 sm:ml-2.5 w-full">
          <span className="mb-2.5 text-base font-semibold flex text-[#2F1160]">
            {ADULT_LAST_NAME_LABEL}
          </span>
          <input
            type="text"
            name="last_name"
            onChange={handleOnChangeInput}
            onBlur={blurInputHandler}
            value={capitalizeLastName}
            placeholder="Last Name"
            ref={lastNameRef}
            className={`outline-0 px-[14px] py-3 text-base border capitalize rounded-xl w-full ${
              errors.last_name && 'border-red-500'
            }`}
          />
          {<p className="text-red-500 text-sm">{errors.last_name}</p>}
        </div>
      </div>
      <div className="sm:flex">
        <div className="mb-4 sm:mr-2.5 w-full">
          <span className="mb-2.5 text-base font-semibold flex text-[#2F1160]">
            {ADULT_DATE_OF_BIRTH_LABEL}
          </span>
          <CustomDatepicker datepickerUsedFor="Adult Form" />
        </div>
        <div className="mb-4 sm:ml-2.5 w-full">
          <span className="mb-2.5 text-base font-semibold flex text-[#2F1160]">
            {ADULT_GENDER_LABEL}
          </span>
          <select
            name="gender"
            onChange={handleOnChangeSelect}
            onBlur={handleBlurSelect}
            value={driver.gender ?? 'select'}
            className={`outline-0 px-4 py-3 text-base border rounded-xl w-full appearance-none ${
              errors.gender && 'border-red-500'
            }`}
          >
            <option value="select" disabled>
              {ADULT_DEFAULT_GENDER}
            </option>
            <option value="male">{ADULT_GENDER_MALE}</option>
            <option value="female">{ADULT_GENDER_FEMALE}</option>
            <option value="non-disclose">{ADULT_GENDER_NOTDISCLOSE}</option>
          </select>
          {<p className="text-red-500 text-sm">{errors.gender}</p>}
        </div>
      </div>
      <div className="sm:flex sm:items-center">
        <div className="mb-4 sm:mr-2.5 w-full">
          <span className="mb-2.5 text-base font-semibold flex text-[#2F1160]">
            {ADULT_MOBILE_PHONE_LABEL}
          </span>
          <PhoneInput
            type="text"
            name="phone_number"
            onChange={val =>
              handleChangeDriverFields({
                phone_number: val?.toString() ?? '',
              })
            }
            onBlur={blurInputHandler}
            value={driver.phone_number}
            placeholder="1234567890"
            ref={phoneRef}
            className={`outline-0 px-4 py-3 text-base border rounded-xl w-full ${
              errors.phone_number && 'border-red-500'
            }`}
            defaultCountry="AU"
          />

          {<p className="text-red-500 text-sm">{errors.phone_number}</p>}
        </div>
      </div>
      <div className="mb-5 relative">
        <span className="mb-2.5 text-base font-semibold flex text-[#2F1160]">
          {ADULT_ADDRESS_LABEL}
        </span>
        <input
          autoComplete="off"
          type="text"
          onChange={e => {
            setValue(e.target.value)
            handleOnChangeInput(e)
          }}
          value={value}
          disabled={!ready}
          placeholder="Address"
          name="address"
          className={`outline-0 px-4 py-3 text-base border rounded-xl w-full ${
            (errors.address || errors.zip_code) && 'border-red-500'
          }`}
          ref={addressRef}
          onBlur={e => {
            blurInputHandler(e)
            setAddressInteracted(true)
          }}
        />
        {
          <p className="text-red-500 text-sm">
            {errors.address
              ? errors.address
              : addressInteracted && !driver.zip_code && !driver.city
              ? 'Please select an address from the suggested addresses dropdown'
              : ''}
          </p>
        }
        {status === 'OK' && (
          <ul className="absolute bg-white border rounded-xl py-0.5 p-3 top-full max-h-[350px] overflow-auto z-[999] w-full shadow-lg">
            {renderSuggestions()}
          </ul>
        )}
      </div>

      <p className="text-red-500 text-sm font-[700] text-center my-[10px]">
        {errors.tcs_agreed || errors.risk_agreement_agreed}
      </p>
      <div className="flex justify-between items-center gap-[10px]">
        <button
          className={`w-fit self-center items-center border-[2px] border-[#9D62FE] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full text-[#9D62FE] w-full`}
          onClick={() => backToDriverForm()}
        >
          Back
        </button>
        <button
          className={`w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full w-full text-white ${
            isAllInputsFilled ? 'cursor-pointer' : 'cursor-not-allowed'
          }`}
          onClick={() => handleNextComponent()}
          type="button"
          onMouseOver={() => {
            if (!(selectedDOB.day || selectedDOB.month || selectedDOB.year)) {
              dispatch({
                type: 'SET_SELECTED_DOB_ERROR',
                payload: {
                  selectedDOBError:
                    'Please make sure you selected your date of birth.',
                },
              })
            }
          }}
          disabled={!isAllInputsFilled}
        >
          Next
        </button>
      </div>
    </>
  )
}

export default AdultDriverFormOnly
