import React from 'react'
import {
  TERMS_CONDITIONS_LABEL,
  TERMS_CONDITIONS_SUBTITLE,
  TERMS_CONDITIONS_TEXT,
} from './Constants'

type Props = {}

const TermsConditions = (props: Props) => {
  return (
    <>
      <div className="text-[14px] sm:text-[16px] font-[700] mb-3 text-[#2F1160]">
        {TERMS_CONDITIONS_LABEL}
      </div>
      <div className="flex mb-2.5">
        <div className="w-2.5 h-2.5 mr-2.5 mt-1">
          <div className="bg-[#9D62FE] w-[16px] h-[16px] rounded-full"></div>
        </div>
        <p className="font-normal text-sm uppercase">{TERMS_CONDITIONS_TEXT}</p>
      </div>
      <div className="w-full shadow-inner px-4 py-3 mb-4 text-sm max-h-56 overflow-y-scroll">
        <p>
          <span className="underline">{TERMS_CONDITIONS_SUBTITLE}</span>
          <br />
          I am the participant named above and in consideration of Hyper Karting
          Pty Ltd (“the Provider”) providing services and/or equipment to enable
          me to participate in Karting (“the Activity”), I agree as follows:
          <br />
          1. I will comply with all rules and directions provided by the
          Provider and their employees including, but not limited to:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (a) Reading and complying with all safety
          instructions and signs in the kiosk and at the entrance of the
          Activity prior to my participation; and
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (b) complying with all verbal and/or video
          instructions and directions by employees of the Provider.
          <br />
          2. I understand, acknowledge and agree that:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (a) I will exercise due care and skill while
          participating in the Activity;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (b) the Activity is dangerous and may result
          in serious injury, and my participation in the Activity and my use of
          any equipment related to the Activity involves inherent risks that
          will sometimes occur; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (c) the Activity requires physical fitness,
          skill and mental alertness; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (d) my participation in the Activity and my
          use of such equipment may result in my injury or illness including but
          not limited to bodily injury, disease, strains, fractures, partial
          and/or total paralysis, eye injury, blindness, heat stroke, heart
          attack, death or other ailments that could cause serious disability;{' '}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (e) these risks and dangers may be caused by
          the negligence of the owners, employees, officers or agents of the
          Provider, the negligence of the participants, the negligence of
          others, accidents, breaches of contract the forces of nature or other
          causes. These risks and dangers may arise from foreseeable or
          unforeseeable causes;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (f) the Provider has not made any
          representations in respect to the adequacy of my skills, level of
          physical or mental fitness, psychological state or any other capacity
          in relation to my participation in the Activity; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (g) I have made or will make full disclosure
          in writing to the Provider about health issues or other relevant
          matters of any kind concerning me and which might reasonably be
          expected to impact upon my ability to participate in the Activity; and
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (h) I participate in the Activity solely at
          my own risk.
          <br />
          3. I further understand, acknowledge and agree that:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (a) the Go Karts travel at high speeds and
          are dangerous;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (b) there is no insurance coverage for kart
          collisions or injury I may cause to another participant; and
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (c) participation is prohibited for drivers
          who have consumed any alcohol or illicit substances.
          <br />
          4. I release and hold harmless the Provider, and all associated
          entities of the Provider, including but not limited to Hyper Karting
          Pty Ltd, and their respective shareholders, directors, officers,
          employees and agents from all liabilities arising from my
          participation in the Activity and from my use of any equipment
          provided by the Provider including, but not limited to, any and all
          claims, actions, demands or losses for bodily injury, death, and loss
          or damage to property which I may have now or at any time in the
          future (including where such claims, actions, demands or losses arise
          from any negligent act or omission to act by the Providers).
          <br />
          5. By signing this document, I also acknowledge, agree, and understand
          that the risk warning above constitutes a 'risk warning' for the
          purposes of the relevant legislation, including for the purpose of
          section 5M of the Civil Liability Act 2002 (NSW)
          <br />
          6. I agree to indemnify, defend and hold harmless the Provider, and
          all associated entities of the Provider, including but not limited to
          Hyper Karting Pty Ltd, and their shareholders, directors, officers,
          employees and agents from any and all third party claims, liability,
          damages of every kind of nature whether known or unknown and/or costs
          (including, but not limited to, legal costs) arising from or in any
          way related to my participation in the Activity and my use of any
          equipment provided by the Providers.
          <br />
          7. I request and agree that first aid or other appropriate medical
          treatment be administered to me in such manner as the Provider or any
          of its officers or employees or agents shall deem necessary. In such
          an event, I agree to indemnify, hold harmless and forever discharge
          the parties listed above for all injury or damage incurred in the
          course of administering the First Aid.
          <br />
          8. I hereby consent to the Provider to take photographs and or video
          footage where I may feature and distribute it on any medium including
          but not limited to print and online. I understand I will not be paid
          for giving this consent and I hereby waive any right to remuneration
          or any fee in respect to its use. <br />
        </p>
        <h4>WARNING UNDER THE AUSTRALIAN CONSUMER LAW</h4>
        <p>
          Under the Australian Consumer Law (New South Wales), several statutory
          guarantees apply to the supply of certain goods and services. These
          guarantees mean that the supplier named on this form is required to
          ensure that the recreational services it supplies to you— <br />
          &nbsp;&nbsp;&nbsp;&nbsp;• are rendered with due care and skill; and{' '}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;• are reasonably fit for any purpose which
          you, either expressly or by implication, make known to the supplier;
          and <br />
          &nbsp;&nbsp;&nbsp;&nbsp;• might reasonably be expected to achieve any
          result you have made known to the supplier. <br />
          1. Section 139A of Competition and Consumer Act 2010 (Cth) permits the
          Provider of the Recreational Activities and associated services to ask
          you to agree that the statutory guarantees under the Australian
          Consumer Law (Cth) do not apply to you (or a person for whom or on
          whose behalf you are acquiring the services <br />
          2. By signing this document, you acknowledge, agree and understand
          that, to the full extent permitted by law, the liability of the
          Provider in relation to recreational services (as that term is defined
          in the Australian Consumer Law (Cth) and any similar state laws) and
          recreational activities (as that term is defined in the Civil
          Liability Act 2002 (NSW)) for any: <br />
          &nbsp;&nbsp;&nbsp;&nbsp; a. death; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; b. physical or mental injury (including the
          aggravation, acceleration or recurrence of such an injury); <br />
          &nbsp;&nbsp;&nbsp;&nbsp; c. the contraction, aggravation or
          acceleration of a disease; d. the coming into existence, the
          aggravation, acceleration or recurrence of any other condition,
          circumstance, occurrence, activity, form of behaviour, course of
          conduct or state of affairs: <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; i. that is or may be
          harmful or disadvantageous to you or the community; <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ii. that may result
          in harm or disadvantage to you or the community; <br />
          that may be suffered by you (or a person for whom or on whose behalf
          you are acquiring the services) resulting from the supply of
          recreational services or recreational activities is excluded. <br />
          3. By signing this document, to the full extent permitted by law, you
          (or the person for whom or on whose behalf you are acquiring the
          services) agree to waive and/or release the Provider, its servants and
          agents, from any claim, right or cause of action which you or your
          heirs, successors, executors, administrators, agents and assigns might
          otherwise have against the Provider, its servant and agents, for or
          arising out of your death or physical or mental injury, disease, loss
          and damage, or economic loss of any description whatsoever which you
          may suffer or sustain in the course of or consequential upon or
          incidental to your participation in the Activity, whether caused by
          the negligence of the Provider, its servant and agents, or otherwise.
          <br />
          4. You do not have to agree to exclude, restrict or modify or waive
          your rights against, or release, the Service Provider, its servants
          and agents, from any claims by signing this document, however the
          Service Provider may refuse to allow you to participate in the
          Recreational Activities, or to provide you with the associated
          services, if you do not agree to exclude, restrict, modify or waive
          your rights against, or release, the Service Provider, its servants
          and agents, by signing this document.
        </p>
        <b>
          Signed by the Participant as an acceptance of the terms and conditions
          above:
        </b>
        <h4>
          For participants under 18 years of age:
          <br />
          (required for all underage participants)
        </h4>
        <p>
          I hereby: <br />
          1. confirm that I am over the age of eighteen years and that I am the
          guardian of the participant; <br />
          2. acknowledge that I have read and clearly understand each of the
          conditions hereof;
          <br />
          3. warrant and declare that the information set out in this document
          is true and correct in every particular;
          <br />
          4. accept full liability and responsibility for the actions of the
          participant under the above terms and conditions during their use of
          the activity facilities and will ensure that they comply with the
          above terms and conditions; and
          <br />
          5. indemnify and will keep indemnified the Provider and all associated
          entities of the Provider, including but not limited to Hyper Karting
          Pty Ltd and its officers, agents and personnel from all actions,
          claims, demands or proceedings made by or on behalf of the participant
          or any third party acting on behalf of the participant, including with
          respect to any failure by the participant to comply with the terms and
          conditions above.
        </p>
      </div>
    </>
  )
}

export default TermsConditions
