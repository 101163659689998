import React, {
  useContext,
  FocusEventHandler,
  ChangeEventHandler,
  useEffect,
  MouseEventHandler,
  ChangeEvent,
  useRef,
  useMemo,
} from 'react'
import { IDriver } from '../../interfaces/Registration'
import {
  ADULT_CLEAR_SIGN_LABEL,
  EMAIL_CONSENT_TEXT,
  RISK_AGREEMENT_AGREED_TEXT,
  TERMS_CONDITIONS_AGREED_TEXT_1,
  TERMS_CONDITIONS_AGREED_TEXT_2,
} from './Constants'
import TermsConditions from './TermsConditions'
import SignaturePad from 'react-signature-canvas'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import { convertDob } from '../../utils/RegistrationHelpers'
import { postRequest } from '../../api'
import Capitalize from '../../utils/Capitalize'

type Props = {
  driver: IDriver
  errors: any
  editMode: boolean
  handleOnChangeInput: ChangeEventHandler<HTMLInputElement>
  handleBlurInput: Function
  handleOnChangeSelect: ChangeEventHandler<HTMLSelectElement>
  handleBlurSelect: FocusEventHandler<HTMLSelectElement>
  backToPreviousComponent: Function
  openSuccessScreen: Function
}

const AdultDriverCompletion = (props: Props) => {
  const {
    editMode,
    handleBlurInput,
    backToPreviousComponent,
    openSuccessScreen,
  } = props
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue

  const signRef = useRef<any>(null)

  const {
    driver,
    dispatch,
    orderId,
    email,
    isGenericDriverRegistration,
    setLoading,
    getDrivers,
    selectedDOB,
  } = registrationContext

  // test

  const handleSubmit = async (driverList: IDriver[]) => {
    setLoading(true)
    const requestURL = editMode ? 'updateDrivers' : 'createDrivers'

    const { data } = await postRequest(requestURL, {
      data: {
        drivers: driverList,
      },
    })
    let newDriverResponse = data.result.drivers

    if (!isGenericDriverRegistration) {
      const res = await postRequest('addDriverToBooking', {
        data: {
          drivers: newDriverResponse,
          orderId: orderId,
        },
      })
      console.log('Recieved res addDriverToBooking: ', res)
      await getDrivers(email)
    }
    setLoading(false)
    openSuccessScreen()
  }

  const capitalizeFirstName = useMemo(
    () => driver && Capitalize(driver.first_name),
    [driver]
  )

  const capitalizeLastName = useMemo(
    () => driver && Capitalize(driver.last_name),
    [driver]
  )

  const submitButtonHandler: MouseEventHandler<HTMLButtonElement> = () => {
    const _driver = editMode
      ? ({
          ...driver,
          first_name: capitalizeFirstName,
          last_name: capitalizeLastName,
          city_id: 2284,
          country: 'AU',
          city: 'Sydney',
          dob: driver && convertDob(driver.dob),
        } as IDriver)
      : ({
          ...driver,
          first_name: capitalizeFirstName,
          last_name: capitalizeLastName,
          dob: driver && convertDob(driver.dob),
        } as IDriver)

    delete _driver.guardian_dob
    delete _driver.guardian_first_name
    delete _driver.guardian_last_name
    handleSubmit([_driver])
  }

  // when the component dismount the driver.dob value is getting empty string so resign it
  const months = useMemo(
    () => [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    []
  )

  useEffect(() => {
    if (driver) {
      if (
        selectedDOB &&
        selectedDOB.day &&
        selectedDOB.month &&
        selectedDOB.year &&
        !driver.dob
      ) {
        const reformatDate = `${selectedDOB.day.padStart(2, '0')}-${(
          months.indexOf(selectedDOB.month) + 1
        )
          .toString()
          .padStart(2, '0')}-${selectedDOB.year}`
        dispatch({
          type: 'SET_REGISTRATION_DRIVER',
          payload: { driver: { ...driver, dob: reformatDate } },
        })
      }
    }
  }, [dispatch, driver, months, selectedDOB])

  const blurInputHandler: FocusEventHandler<HTMLInputElement> = e => {
    handleBlurInput(e.target.name, false)
  }

  const handleEmailConsent = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: 'SET_REGISTRATION_DRIVER',
      payload: {
        driver: { ...driver, email_consent: event.target.checked } as IDriver,
      },
    })
  }

  const handleTCSAgreed = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: 'SET_REGISTRATION_DRIVER',
      payload: {
        driver: { ...driver, tcs_agreed: event.target.checked } as IDriver,
      },
    })
  }

  const handleRiskAgreementAgreed = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({
      type: 'SET_REGISTRATION_DRIVER',
      payload: {
        driver: {
          ...driver,
          risk_agreement_agreed: event.target.checked,
        } as IDriver,
      },
    })
  }

  const clearSignature = () => {
    signRef?.current?.clear()
    dispatch({
      type: 'SET_REGISTRATION_DRIVER',
      payload: {
        driver: {
          ...driver,
          signature_url: '',
        } as IDriver,
      },
    })
  }

  useEffect(() => {
    // send reducer action
    if (window.location.pathname.includes('/bookings/') && orderId) {
      const itemRegistrationLink = `${window.location.origin}/bookings/${orderId}`
      dispatch({
        type: 'SET_REGISTRATION_QR_LINK',
        payload: {
          itemRegistrationLink: itemRegistrationLink,
        },
      })
    }
  }, [dispatch, orderId])

  return (
    <>
      <div className="text-center">
        <h1 className="text-center text-[#000] text-[20px] sm:text-[30px] font-[700]">
          Registration Summary
        </h1>
        {driver && (
          <div className="my-[15px] lg:my-[30px]">
            <p className="text-[12px] font-[700] text-[#777] capitalize">
              {capitalizeFirstName} {capitalizeLastName}
            </p>
            <p className="text-[12px] font-[700] text-[#777]">
              {driver.address}
            </p>
            <p className="text-[12px] font-[700] text-[#777]">
              ({driver.phone_number})
            </p>
          </div>
        )}
      </div>
      <TermsConditions />
      <div className="text-[14px] sm:text-[16px] font-[700] mb-3 font-semibold text-left text-[#2F1160]">
        Please Sign Here
      </div>
      <div className="h-[200px]">
        <SignaturePad
          penColor="black"
          clearOnResize={false}
          canvasProps={{
            className:
              'sigCanvas outline-0 text-base border rounded-xl resize-none mb-5 w-full h-full',
          }}
          ref={signRef}
          onEnd={() => {
            dispatch({
              type: 'SET_REGISTRATION_DRIVER',
              payload: {
                driver: {
                  ...driver,
                  signature_url: signRef?.current.toDataURL(),
                } as IDriver,
              },
            })
          }}
        />
      </div>
      <div className="mb-4 flex" onClick={clearSignature}>
        {/* {<p className="text-red-500 text-sm">{errors.signature_url}</p>} */}
        <div className="text-sm underline text-slate-500 pr-1 cursor-pointer w-max ml-auto">
          {ADULT_CLEAR_SIGN_LABEL}
        </div>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          name="tcs_agreed"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleTCSAgreed(event)
          }
          onBlur={blurInputHandler}
          checked={driver && driver.tcs_agreed}
          className="sm:w-4 sm:h-4 w-[18px] h-[18px] mr-2.5 cursor-pointer hidden"
          id="tcs_agreed"
        />
        {driver && driver.tcs_agreed ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="mr-2.5 cursor-pointer"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() =>
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      tcs_agreed: !driver.tcs_agreed,
                    } as IDriver,
                  },
                })
              }
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="2.5"
                fill="#2EBF3C"
                stroke="#555758"
              />
              <path
                d="M5 7.875L6.875 9.75L10.625 6"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </>
        ) : (
          <>
            <div
              className="w-[16px] h-[16px] rounded-[3px] border-[1px] border-[#555758] mr-2.5 cursor-pointer"
              onClick={() =>
                driver &&
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      tcs_agreed: !driver.tcs_agreed,
                    } as IDriver,
                  },
                })
              }
            ></div>
          </>
        )}
        <label
          htmlFor="tcs_agreed"
          className="text-slate-500 text-sm font-normal sm:max-w-[calc(100%_-_1.625rem)] max-w-[calc(100%_-_2.625rem)] cursor-pointer"
        >
          <sup>*</sup>
          {TERMS_CONDITIONS_AGREED_TEXT_1}
          <a
            className="underline"
            href="https://www.racefacer.com/en/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>
          ,&nbsp;
          <a
            className="underline"
            href="https://www.racefacer.com/en/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          {TERMS_CONDITIONS_AGREED_TEXT_2}
        </label>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          name="risk_agreement_agreed"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleRiskAgreementAgreed(event)
          }
          onBlur={blurInputHandler}
          checked={driver && driver.risk_agreement_agreed}
          className="sm:w-4 sm:h-4 w-[18px] h-[18px] mr-2.5 cursor-pointer hidden"
          id="risk_agreement_agreed"
        />
        {driver && driver.risk_agreement_agreed ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="mr-2.5 cursor-pointer"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() =>
                driver &&
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      risk_agreement_agreed: !driver.risk_agreement_agreed,
                    } as IDriver,
                  },
                })
              }
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="2.5"
                fill="#2EBF3C"
                stroke="#555758"
              />
              <path
                d="M5 7.875L6.875 9.75L10.625 6"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </>
        ) : (
          <>
            <div
              className="w-[16px] h-[16px] rounded-[3px] border-[1px] border-[#555758] mr-2.5 cursor-pointer"
              onClick={() =>
                driver &&
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      risk_agreement_agreed: !driver.risk_agreement_agreed,
                    } as IDriver,
                  },
                })
              }
            ></div>
          </>
        )}
        <label
          htmlFor="risk_agreement_agreed"
          className="text-slate-500 text-sm font-normal max-w-[calc(100%_-_2.625rem)] sm:max-w-[calc(100%_-_1.625rem)] cursor-pointer"
        >
          <sup>*</sup>
          {RISK_AGREEMENT_AGREED_TEXT}
        </label>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          name="email_consent"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleEmailConsent(event)
          }
          onBlur={blurInputHandler}
          checked={driver && driver.email_consent}
          className="sm:w-4 sm:h-4 w-[18px] h-[18px] mr-2.5 cursor-pointer hidden"
          id="email_consent"
        />
        {driver && driver.email_consent ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="mr-2.5 cursor-pointer"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() =>
                driver &&
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      email_consent: !driver.email_consent,
                    } as IDriver,
                  },
                })
              }
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="2.5"
                fill="#2EBF3C"
                stroke="#555758"
              />
              <path
                d="M5 7.875L6.875 9.75L10.625 6"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </>
        ) : (
          <>
            <div
              className="w-[16px] h-[16px] rounded-[3px] border-[1px] border-[#555758] mr-2.5 cursor-pointer"
              onClick={() =>
                driver &&
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      email_consent: !driver.email_consent,
                    } as IDriver,
                  },
                })
              }
            ></div>
          </>
        )}
        <label
          htmlFor="email_consent"
          className="text-slate-500 text-sm font-normal max-w-[calc(100%_-_2.625rem)] sm:max-w-[calc(100%_-_1.625rem)] cursor-pointer"
        >
          {EMAIL_CONSENT_TEXT}
        </label>
      </div>
      <p className="text-red-500 text-sm">
        {driver &&
          (!driver.tcs_agreed || !driver.risk_agreement_agreed) &&
          driver.signature_url === '' &&
          'Please sign and accept the Terms and Conditions'}
      </p>
      <div className="flex justify-between items-center gap-[10px]">
        <button
          className={`w-fit self-center items-center border-[2px] border-[#9D62FE] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full text-[#9D62FE] w-full`}
          onClick={() => backToPreviousComponent()}
        >
          Back
        </button>
        <button
          className={`w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full w-full text-white ${
            driver &&
            driver.tcs_agreed &&
            driver.risk_agreement_agreed &&
            driver.signature_url !== ''
              ? 'cursor-pointer'
              : 'cursor-not-allowed opacity-50'
          }`}
          onClick={submitButtonHandler}
          disabled={
            !(
              driver &&
              driver.tcs_agreed &&
              driver.risk_agreement_agreed &&
              driver.signature_url
            )
          }
        >
          <span className="hidden sm:block">Complete Registration</span>
          <span className="sm:hidden">Complete</span>
        </button>
      </div>
    </>
  )
}

export default AdultDriverCompletion
