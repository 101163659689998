import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastProvider } from '../../context/ToastContext'
import Registration from '../Registration'
import BookingDetails from '../Registration/BookingDetails'
import RegistrationBaseWrapper from '../Registration/RegistrationBaseWrapper'

const App = () => {
  return (
    <>
      <ToastProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <RegistrationBaseWrapper isGenericDriverRegistration={true} />
              }
            >
              <Route index element={<Registration />}></Route>
            </Route>
            <Route
              path="bookings/:bookingId"
              element={<RegistrationBaseWrapper />}
            >
              <Route index element={<BookingDetails />}></Route>
              <Route path="register-driver" element={<Registration />} />
            </Route>
          </Routes>
        </Router>
      </ToastProvider>
    </>
  )
}

export default App
