import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IDriver } from '../../interfaces/Registration'
import CustomDatepicker from './CustomDatepicker'

import {
  ADULT_DEFAULT_GENDER,
  ADULT_GENDER_FEMALE,
  ADULT_GENDER_MALE,
  ADULT_GENDER_NOTDISCLOSE,
  JUNIOR_DOB_LABEL,
  JUNIOR_FIRST_NAME_LABEL,
  JUNIOR_GENDER_LABEL,
  JUNIOR_LAST_NAME_LABEL,
} from './Constants'
import useError from './CustomHookValidation'
import {
  IRegistrationStateValue,
  RegistrationContext,
} from './RegistrationBaseWrapper'
import Capitalize from '../../utils/Capitalize'

type Props = {
  idx: Number
  driver: IDriver
}

const JuniorFormItem = (props: Props) => {
  const { idx, driver } = props

  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue

  const [isAccordionOpen, setAccordionOpen] = useState<boolean>(true)

  const { dispatch, juniors, drivers } = registrationContext

  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen)
  }

  const { errors, performValidation, removeErrorFromInputField } = useError(
    registrationContext?.driver as IDriver,
    [...juniors, ...drivers]
  )

  useEffect(() => {
    if (errors) {
      const hasErrors = Object.keys(errors).some(key => {
        return (
          (key.startsWith('first_name') ||
            key.startsWith('last_name') ||
            key.startsWith('gender') ||
            key.startsWith('dob')) &&
          errors[key]
        )
      })

      dispatch({
        type: 'SET_IS_CHILD_FORM_HAS_ERROR',
        payload: {
          hasError: hasErrors,
        },
      })
    }
  }, [dispatch, errors])

  const handleBlurInput = (targetName: string, isJunior: boolean) => {
    const { hasError } = isJunior
      ? performValidation(targetName, 'all', true, Number(idx))
      : performValidation(targetName)
    if (!hasError) {
      removeErrorFromInputField(targetName)
    }
  }
  const removeJunior = (index: number) => {
    registrationContext.dispatch({
      type: 'SET_DRIVERS',
      payload: {
        drivers: [
          ...registrationContext.drivers.slice(0, index),
          ...registrationContext.drivers.slice(index + 1),
        ],
      },
    })
  }
  const handleOnChangeInput: ChangeEventHandler<HTMLInputElement> = e => {
    if (['first_name', 'last_name', 'dob', 'gender'].includes(e.target.name)) {
      let val = e.target.value
      const index = +(e?.target?.dataset?.index || 0)
      if (e.target.name === 'dob') {
        val = val.replace(/\//g, '-')
        val = val.replace(/\./g, '-')
        if (!e.target.defaultValue.endsWith('-')) {
          if (val.length === 2 && !isNaN(val as any)) {
            val = val + '-'
          }
          if (
            val.length === 5 &&
            !isNaN((val.slice(0, 2) + val.slice(3)) as any)
          ) {
            val = val + '-'
          }
        }
        val = val.slice(0, 10)
      }
      let xJuniors = [
        ...registrationContext.drivers,
        ...registrationContext.juniors,
      ]
      const updatedJunior = { ...xJuniors[index], [e.target.name]: val }
      xJuniors[index] = updatedJunior

      registrationContext.dispatch({
        type: 'SET_DRIVERS',
        payload: {
          drivers: xJuniors,
        },
      })
    }
  }

  const handleInputSelector: ChangeEventHandler<HTMLSelectElement> = e => {
    if (['first_name', 'last_name', 'dob', 'gender'].includes(e.target.name)) {
      const index = +(e?.target?.dataset?.index || 0)
      let xJuniors = [
        ...registrationContext.drivers,
        ...registrationContext.juniors,
      ]
      const junior = { ...xJuniors[index], [e.target.name]: e.target.value }
      xJuniors[index] = junior

      registrationContext.dispatch({
        type: 'SET_DRIVERS',
        payload: {
          drivers: xJuniors,
        },
      })
    }
  }

  const blurInputHandler: FocusEventHandler<HTMLInputElement> = e => {
    handleBlurInput(e.target.name, true)
  }

  const blurInputSelector: FocusEventHandler<HTMLSelectElement> = e => {
    handleBlurInput(e.target.name, true)
  }

  const capitalizeFirstName = useMemo(
    () => driver.first_name && Capitalize(driver.first_name),
    [driver.first_name]
  )

  const capitalizeLastName = useMemo(
    () => driver.last_name && Capitalize(driver.last_name),
    [driver.last_name]
  )

  return (
    <div
      className={`border rounded-[10px] border-[#e5e5e5] overflow-hidden ${
        idx !== 0 && 'mt-[15px]'
      }`}
    >
      <div
        className={`bg-[#F7F5FE] py-[15px] px-[10px] flex items-center justify-between cursor-pointer ${
          isAccordionOpen ? 'border-b' : ''
        }`}
        onClick={toggleAccordion}
      >
        <span className="text-[14px] font-[700] capitalize">
          {capitalizeFirstName && capitalizeLastName
            ? capitalizeFirstName + ' ' + capitalizeLastName
            : Number(idx) < 9
            ? `driver - 0${Number(idx) + 1}`
            : `driver - ${Number(idx) + 1}`}
        </span>
        <div className="flex items-center gap-[15px]">
          {idx !== 0 && (
            <div
              className="cursor-pointer flex items-center gap-[5px] border-[2px] border-[#C2C0C3] rounded-full bg-white py-[2px] px-[10px]"
              onClick={() => {
                removeJunior(Number(idx))
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.6794 3.27685C12.9226 3.27685 13.125 3.47873 13.125 3.7356V3.9731C13.125 4.22372 12.9226 4.43184 12.6794 4.43184H2.32116C2.07741 4.43184 1.875 4.22372 1.875 3.9731V3.7356C1.875 3.47873 2.07741 3.27685 2.32116 3.27685H4.14348C4.51366 3.27685 4.83581 3.01373 4.91909 2.64249L5.01452 2.21624C5.16284 1.63562 5.65093 1.25 6.20954 1.25H8.79046C9.34299 1.25 9.83656 1.63562 9.9794 2.18562L10.0815 2.64186C10.1642 3.01373 10.4863 3.27685 10.8571 3.27685H12.6794ZM11.7535 11.9589C11.9438 10.1857 12.2769 5.97329 12.2769 5.93079C12.289 5.80204 12.2471 5.68016 12.1638 5.58204C12.0744 5.49017 11.9614 5.43579 11.8368 5.43579H3.16769C3.04248 5.43579 2.92334 5.49017 2.84067 5.58204C2.75679 5.68016 2.71545 5.80204 2.72153 5.93079C2.72265 5.9386 2.7346 6.08698 2.75459 6.33505C2.84336 7.43709 3.0906 10.5064 3.25036 11.9589C3.36342 13.0288 4.06548 13.7013 5.0824 13.7257C5.86713 13.7438 6.67557 13.7501 7.50224 13.7501C8.28089 13.7501 9.07169 13.7438 9.88073 13.7257C10.9329 13.7076 11.6344 13.047 11.7535 11.9589Z"
                  fill="#C2C0C3"
                />
              </svg>
              <span className="text-[#C2C0C3] text-[12px] font-[700] capitalize">
                remove
              </span>
            </div>
          )}
          {isAccordionOpen ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
              >
                <g opacity="0.75">
                  <circle
                    cx="14.5"
                    cy="14.5"
                    r="13.5"
                    fill="white"
                    stroke="#C2C0C3"
                    strokeWidth="2"
                  />
                  <path
                    d="M9 12L14.5 17L20 12"
                    stroke="#C2C0C3"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </>
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
              >
                <g opacity="0.75">
                  <circle
                    cx="14.5"
                    cy="14.5"
                    r="13.5"
                    transform="rotate(-180 14.5 14.5)"
                    fill="white"
                    stroke="#C2C0C3"
                    strokeWidth="2"
                  />
                  <path
                    d="M20 17L14.5 12L9 17"
                    stroke="#C2C0C3"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </>
          )}
        </div>
      </div>
      {isAccordionOpen && (
        <div className="px-[20px] pb-[20px]">
          <div className="flex items-center justify-between flex-wrap">
            <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
              <label className="text-base	leading-5 mb-[10px]">
                {JUNIOR_FIRST_NAME_LABEL}
              </label>
              <input
                name="first_name"
                onChange={handleOnChangeInput}
                onBlur={blurInputHandler}
                value={capitalizeFirstName ?? ''}
                type="text"
                placeholder="First Name"
                className={`outline-0 px-4	py-3 text-base border rounded-xl w-full capitalize ${
                  errors[`first_name${idx}`] && 'border-red-500'
                }`}
                data-index={idx}
              />
              {errors[`first_name${idx}`] && (
                <p className="text-red-500 text-sm">
                  {errors[`first_name${idx}`]}
                </p>
              )}
            </div>
            <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
              <label className="text-base	leading-5 mb-[10px]">
                {JUNIOR_LAST_NAME_LABEL}
              </label>
              <input
                type="text"
                name="last_name"
                onChange={handleOnChangeInput}
                onBlur={blurInputHandler}
                value={Capitalize(driver.last_name) ?? ''}
                placeholder="Last Name"
                className={`outline-0 px-4	py-3 text-base border rounded-xl w-full capitalize ${
                  errors[`last_name${idx}`] && 'border-red-500'
                }`}
                data-index={idx}
              />
              {errors[`last_name${idx}`] && (
                <p className="text-red-500 text-sm">
                  {errors[`last_name${idx}`]}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between flex-wrap">
            <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
              <label className="text-base	leading-5 mb-[10px]">
                {JUNIOR_DOB_LABEL}
              </label>

              <CustomDatepicker
                datepickerUsedFor="Junior Form"
                juniorItem={{ driver: driver, index: +idx }}
              />

              {driver.juniorDOBErrorMesssage && (
                <p className="text-red-500 text-sm">
                  {driver.juniorDOBErrorMesssage}
                </p>
              )}
            </div>
            <div className="flex flex-col w-full sm:w-[calc(38%_-_10px)] mt-4">
              <label className="text-base	leading-5 mb-[10px]">
                {JUNIOR_GENDER_LABEL}
              </label>
              <select
                name="gender"
                onChange={handleInputSelector}
                onBlur={blurInputSelector}
                value={driver.gender ?? 'select'}
                className={`outline-0 px-4	py-3 text-base border rounded-xl w-full appearance-none ${
                  errors[`gender${idx}`] && 'border-red-500'
                }`}
                data-index={idx}
              >
                <option value="select" disabled>
                  {ADULT_DEFAULT_GENDER}
                </option>
                <option value="male">{ADULT_GENDER_MALE}</option>
                <option value="female">{ADULT_GENDER_FEMALE}</option>
                <option value="non-disclose">{ADULT_GENDER_NOTDISCLOSE}</option>
              </select>
              {errors[`gender${idx}`] && (
                <p className="text-red-500 text-sm">{errors[`gender${idx}`]}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default JuniorFormItem
