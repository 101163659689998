import React from 'react'

type HeaderProps = {
  title: string
  description?: string
}

const Header = (props: HeaderProps) => {
  const { title, description } = props
  return (
    <>
      <div className="text-black text-2xl font-semibold mb-2.5 text-center">
        {title}
      </div>
      <div className="sm:mb-7 mb-5 font-normal text-base text-gray-500 text-center">
        {description || ''}
      </div>
    </>
  )
}

export default Header
