import { XMarkIcon } from '@heroicons/react/20/solid'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

type TToastProviderProps = {
  children: ReactNode
}

type TToast = {
  message: string
  duration: number
  visible: boolean
}

type TToastContext = {
  toast: TToast
  displayToast: ({ message }: { message: string }) => void
  hideToast: () => void
}

const ToastContext = createContext({} as TToastContext)

export function useToast() {
  return useContext(ToastContext)
}

export function ToastProvider({ children }: TToastProviderProps) {
  const [toast, setToast] = useState({
    message: '',
    duration: 3000,
    visible: false,
  } as TToast)

  useEffect(() => {
    if (toast.visible) {
      setTimeout(hideToast, toast.duration)
    }
  }, [toast])

  function displayToast({ message }: { message: string }) {
    setToast(toast => ({ ...toast, message, visible: true }))
  }
  function hideToast() {
    setToast(toast => ({ ...toast, visible: false }))
  }

  return (
    <>
      <ToastContext.Provider value={{ displayToast, hideToast, toast }}>
        {children}
        {toast.visible && (
          <>
            <div className="fixed bg-black text-white bottom-20 left-0 right-0 flex items-center w-fit m-auto p-2.5 border-current rounded-md">
              <h3>{toast.message}</h3>
              <XMarkIcon
                className="w-6 h-6 cursor-pointer ml-1.5"
                onClick={hideToast}
              />
            </div>
          </>
        )}
      </ToastContext.Provider>
    </>
  )
}
