import React, { MouseEventHandler, useContext, useEffect } from 'react'
import { IDriver } from '../../interfaces/Registration'
import Header from './Header'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import {
  EMAIL_ADDRESS_LABEL_PREVIEW,
  // BOOKING_REGISTRATION_TITLE,
  PREVIEW_TITLE,
  DRIVER_DETAILS_CORRECT_LABEL,
  // DRIVER_ADD_TO_RACE_BUTTON_LABEL,
  DRIVER_DETAILS_CORRECT_BUTTON_LABEL,
  SCREEN_NAMES,
} from './Constants'
import Capitalize from '../../utils/Capitalize'
import { postRequest } from '../../api'

type Props = {
  driver: IDriver
  setJuniors: Function
  openEditDriverScreen: Function
  openSuccessScreen: Function
}

const DriverPreviewScreen = (props: Props) => {
  const { driver, openSuccessScreen } = props
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const {
    orderId,
    isGenericDriverRegistration,
    isJunior,
    setLoading,
    setEmail,
    // navigation,
    dispatch,
  } = registrationContext

  const goToSuccessScreenHandler: MouseEventHandler<HTMLButtonElement> = () => {
    setEmail(driver.email)
    isGenericDriverRegistration &&
      isJunior &&
      dispatch({
        type: 'SET_DRIVERS',
        payload: {
          drivers: [driver] as IDriver[],
        },
      })
    openSuccessScreen()
  }

  const addDriverToRace = async (drivers: IDriver[], orderId: string) => {
    setLoading(true)
    const res = await postRequest('addDriverToBooking', {
      data: {
        drivers: drivers,
        orderId: orderId,
      },
    })
    const resData = res.data.result.drivers
    if (resData[0]) {
      isJunior
        ? dispatch({
            type: 'SET_DRIVERS',
            payload: {
              drivers: [
                ...drivers,
                {
                  dob: resData[0]?.dob,
                  first_name: resData[0]?.first_name,
                  last_name: resData[0]?.last_name,
                } as IDriver,
              ],
            },
          })
        : dispatch({
            type: 'SET_REGISTRATION_DRIVER',
            payload: {
              driver: {
                ...registrationContext.driver,
                dob: resData[0]?.dob,
                first_name: resData[0]?.first_name,
                last_name: resData[0]?.last_name,
              } as IDriver,
            },
          })
    }
    console.log('Recieved res addDriverToBooking: ', resData)
    setLoading(false)
    openSuccessScreen()
  }

  const handleNext = () => {
    const isDriverIsJunior = driver.isJunior
    if (isJunior && isDriverIsJunior) {
      dispatch({
        type: 'SET_CURRENT_NAVIGATION',
        payload: {
          navigation: {
            previousScreen: SCREEN_NAMES.DRIVER_PREVIEW_SCREEN,
            currentScreen: SCREEN_NAMES.JUNIOR_DRIVER_FORM,
          },
        },
      })
      dispatch({
        type: 'SET_EDIT_MODE',
        payload: {
          editMode: true,
        },
      })
      dispatch({
        type: 'SET_DRIVERS',
        payload: {
          drivers: [driver] as IDriver[],
        },
      })
    } else {
      let capitalizeDriver: IDriver =
        driver && driver.guardian_first_name && driver.guardian_last_name
          ? ({
              ...driver,
              first_name: Capitalize(driver.first_name),
              last_name: Capitalize(driver.last_name),
              guardian_first_name: Capitalize(driver.guardian_first_name),
              guardian_last_name: Capitalize(driver.guardian_last_name),
            } as IDriver)
          : ({
              ...driver,
              first_name: Capitalize(driver.first_name),
              last_name: Capitalize(driver.last_name),
            } as IDriver)
      addDriverToRace([capitalizeDriver], orderId)
    }
  }

  useEffect(() => {
    // send reducer action
    if (window.location.pathname.includes('/bookings/') && orderId) {
      const itemRegistrationLink = `${window.location.origin}/bookings/${orderId}`
      dispatch({
        type: 'SET_REGISTRATION_QR_LINK',
        payload: {
          itemRegistrationLink: itemRegistrationLink,
        },
      })
    }
  }, [dispatch, orderId])

  return (
    <>
      <Header
        title={
          // isGenericDriverRegistration
          //   ? PREVIEW_TITLE
          //   : BOOKING_REGISTRATION_TITLE
          PREVIEW_TITLE
        }
      />
      <div className="mb-5">
        <div className="text-base font-semibold	mb-2.5">
          {EMAIL_ADDRESS_LABEL_PREVIEW}
        </div>
        <input
          type="text"
          name="email"
          className="border border-slate-200 rounded-lg py-3 px-4 outline-0 text-base text-slate-500 w-full"
          value={driver.email}
          disabled
        />
      </div>
      <div className="mb-3.5 text-gray-500 text-base font-normal">
        {DRIVER_DETAILS_CORRECT_LABEL}
      </div>
      <div className="outline-0 px-4	py-3 text-base border rounded-xl w-full resize-none mb-5 capitalize">
        <div>{`${driver.first_name} ${driver.last_name}`}</div>
      </div>
      {isGenericDriverRegistration ? (
        <button
          className="w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full w-full text-white"
          onClick={goToSuccessScreenHandler}
        >
          {DRIVER_DETAILS_CORRECT_BUTTON_LABEL}
        </button>
      ) : (
        <button
          className="w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full w-full text-white"
          onClick={() => handleNext()}
        >
          {DRIVER_DETAILS_CORRECT_BUTTON_LABEL}
        </button>
      )}
    </>
  )
}

export default DriverPreviewScreen
