import { useState } from 'react'
import { DateTime } from 'luxon'
import { ValidateEmail } from '../../utils/RegistrationHelpers'
import { IDriver } from '../../interfaces/Registration'
import { useToast } from '../../context/ToastContext'
import { isValidPhoneNumber } from 'react-phone-number-input'

type TValidationObject = {
  hasError: boolean
  validationErrors: string[]
}
export default function useError(driverData: IDriver, juniors: IDriver[]) {
  const [errors, setErrors] = useState<any>({})
  const toast = useToast()

  const performValidation = (
    key = 'all',
    value: string = '',
    isJunior: boolean = false,
    index?: number
  ) => {
    if (index !== undefined) {
      key = key + index
    }
    console.log('## validating for ', key)
    const validationObject: TValidationObject = {
      hasError: false,
      validationErrors: [],
    }
    const addError = (msg: string | null, key: string) => {
      if (msg) {
        console.log(msg)
        validationObject.hasError = true
        validationObject.validationErrors.push(msg)
      }
      setErrors((errors: any) => {
        let _errors = { ...errors }
        if (msg) {
          _errors = { ..._errors, [key]: msg }
        } else {
          delete _errors[key]
        }
        return _errors
      })
    }
    //handle junior case start
    if (isJunior) {
      if (index !== undefined || key === 'all') {
        if (index === undefined) {
          for (let i = 0; i < juniors.length; i++) {
            if (key.startsWith('first_name') || key === 'all') {
              if (juniors[i] && !juniors[i].first_name) {
                addError('Please enter first name', 'first_name' + i)
              } else {
                addError(null, 'first_name' + i)
              }
            }
            if (key.startsWith('last_name') || key === 'all') {
              if (juniors[i] && !juniors[i].last_name) {
                addError('Please enter last name', 'last_name' + i)
              } else {
                addError(null, 'last_name' + i)
              }
            }
            if (key.startsWith('dob') || key === 'all') {
              if (juniors[i] && !juniors[i].dob) {
                addError('Please enter DOB', 'dob' + i)
              } else if (
                juniors[i] &&
                !DateTime.fromFormat(juniors[i].dob, 'dd-MM-yyyy').isValid
              ) {
                addError('Please enter valid DOB(DD-MM-YYYY)', 'dob' + i)
              } else if (
                juniors[i] &&
                DateTime.fromFormat(juniors[i].dob, 'dd-MM-yyyy').toMillis() >
                  DateTime.now().toMillis()
              ) {
                addError('DOB cannot be a future date', 'dob' + i)
              } else if (
                juniors[i] &&
                DateTime.fromFormat(juniors[i].dob, 'dd-MM-yyyy').toMillis() <
                  DateTime.now().minus({ years: 16, days: 1 }).toMillis()
              ) {
                addError('Drivers age must be under 16', 'dob' + i)
              } else {
                addError(null, 'dob' + i)
              }
            }
            if (key.startsWith('gender') || key === 'all') {
              if (juniors[i] && !juniors[i].gender) {
                addError('Please enter gender', 'gender' + i)
              } else {
                addError(null, 'gender' + i)
              }
            }
          }
        } else {
          console.log('index', index)
          if (key.startsWith('first_name') || key === 'all') {
            if (juniors[index] && !juniors[index].first_name) {
              addError('Please enter first name', key)
            } else {
              addError(null, key)
            }
          }
          if (key.startsWith('last_name') || key === 'all') {
            if (juniors[index] && !juniors[index].last_name) {
              addError('Please enter last name', key)
            } else {
              addError(null, key)
            }
          }
          if (key.startsWith('dob') || key === 'all') {
            if (juniors[index] && !juniors[index].dob) {
              addError('Please enter DOB', key)
            } else if (
              juniors[index] &&
              !DateTime.fromFormat(juniors[index].dob, 'dd-MM-yyyy').isValid
            ) {
              addError('Please enter valid DOB(DD-MM-YYYY)', key)
            } else if (
              juniors[index] &&
              DateTime.fromFormat(juniors[index].dob, 'dd-MM-yyyy').toMillis() >
                DateTime.now().toMillis()
            ) {
              addError('DOB cannot be a future date', key)
            } else if (
              juniors[index] &&
              DateTime.fromFormat(juniors[index].dob, 'dd-MM-yyyy').toMillis() <
                DateTime.now().minus({ years: 16, days: 1 }).toMillis()
            ) {
              addError('Drivers age must be under 16', key)
            } else {
              addError(null, key)
            }
          }
          if (key.startsWith('gender') || key === 'all') {
            if (juniors[index] && !juniors[index].gender) {
              addError('Please enter gender', key)
            } else {
              addError(null, key)
            }
          }
        }
      }
      if (key === 'guardian_first_name' || key === 'all') {
        if (!driverData.guardian_first_name) {
          addError('Please enter first name', 'guardian_first_name')
        } else {
          addError(null, 'guardian_first_name')
        }
      }
      if (key === 'guardian_last_name' || key === 'all') {
        if (!driverData.guardian_last_name) {
          addError('Please enter last name', 'guardian_last_name')
        } else {
          addError(null, 'guardian_last_name')
        }
      }
      if (key === 'guardian_dob' || key === 'all') {
        if (!driverData.guardian_dob) {
          addError('Please enter DOB', 'guardian_dob')
        } else if (
          !DateTime.fromFormat(driverData.guardian_dob, 'dd-MM-yyyy').isValid
        ) {
          addError('Please enter valid DOB(DD-MM-YYYY)', 'guardian_dob')
        } else if (
          DateTime.fromFormat(
            driverData.guardian_dob,
            'dd-MM-yyyy'
          ).toMillis() > DateTime.now().toMillis()
        ) {
          addError('DOB cannot be a future date', 'guardian_dob')
        } else if (
          DateTime.fromFormat(
            driverData.guardian_dob,
            'dd-MM-yyyy'
          ).toMillis() > DateTime.now().minus({ years: 18, days: 1 }).toMillis()
        ) {
          addError('Guardian age must be over 18', 'guardian_dob')
          toast.displayToast({
            message: 'Guardian age must be over 18',
          })
        } else if (
          +DateTime.fromFormat(driverData.guardian_dob, 'dd-MM-yyyy').year <
          1900
        ) {
          addError('Enter valid date', 'guardian_dob')
        } else {
          addError(null, 'guardian_dob')
        }
      }
    }
    //handle junior case end

    if (index === undefined && !isJunior) {
      if (key === 'email' || key === 'all') {
        if (value === '') {
          value = driverData.email
        }
        if (!ValidateEmail(value)) {
          addError('Email Id is invalid', 'email')
        } else {
          addError(null, 'email')
        }
      }

      if (key === 'first_name' || key === 'all') {
        if (!driverData.first_name) {
          addError('Please enter first name', 'first_name')
        } else {
          addError(null, 'first_name')
        }
      }
      if (key === 'last_name' || key === 'all') {
        if (!driverData.last_name) {
          addError('Please enter last name', 'last_name')
        } else {
          addError(null, 'last_name')
        }
      }
      if (key === 'dob' || key === 'all') {
        if (!driverData.dob) {
          addError('Please enter DOB', 'dob')
        } else if (!DateTime.fromFormat(driverData.dob, 'dd-MM-yyyy').isValid) {
          addError('Please enter valid DOB(DD-MM-YYYY)', 'dob')
        } else if (
          DateTime.fromFormat(driverData.dob, 'dd-MM-yyyy').toMillis() >
          DateTime.now().toMillis()
        ) {
          addError('DOB cannot be a future date', 'dob')
        } else if (
          DateTime.fromFormat(driverData.dob, 'dd-MM-yyyy').toMillis() >
          DateTime.now().minus({ years: 16, days: 1 }).toMillis()
        ) {
          addError(
            'Drivers under 16 must have a parent or gaurdian fill out this form',
            'dob'
          )
          toast.displayToast({
            message:
              'Drivers under 16 must have a parent or gaurdian fill out this form',
          })
        } else if (
          +DateTime.fromFormat(driverData.dob, 'dd-MM-yyyy').year < 1900
        ) {
          addError('Enter valid date', 'dob')
        } else {
          addError(null, 'dob')
        }
      }
    }
    if (key === 'phone_number' || key === 'all') {
      if (!driverData.phone_number) {
        addError('Please enter phone number', 'phone_number')
      } else if (!isValidPhoneNumber(driverData.phone_number)) {
        addError(
          `Phone number ${driverData.phone_number} is not valid`,
          'phone_number'
        )
      } else {
        addError(null, 'phone_number')
      }
    }
    if (key === 'gender' || key === 'all') {
      if (!driverData.gender) {
        addError('Please select gender', 'gender')
      } else {
        addError(null, 'gender')
      }
    }
    if (key === 'address' || key === 'all') {
      if (!driverData.address || !driverData.country) {
        addError('Please select valid address', 'address')
      } else {
        addError(null, 'address')
      }
    }
    if (key === 'signature_url' || key === 'all') {
      if (!driverData.signature_url) {
        addError('Please add signature', 'signature_url')
      } else {
        addError(null, 'signature_url')
      }
    }
    if (key === 'tcs_agreed' || key === 'all') {
      if (!driverData.tcs_agreed) {
        addError('Please accept terms and conditions', 'tcs_agreed')
      } else {
        addError(null, 'tcs_agreed')
      }
    }
    if (key === 'risk_agreement_agreed' || key === 'all') {
      if (!driverData.risk_agreement_agreed) {
        addError('Please accept Risk agreement', 'risk_agreement_agreed')
      } else {
        addError(null, 'risk_agreement_agreed')
      }
    }
    if (key === 'zip_code' || key === 'all') {
      if (!driverData.zip_code) {
        addError('Valid Zip code not found for selected address', 'zip_code')
      }
    }

    return validationObject
  }

  const removeErrorFromInputField = (key: string) => {
    setErrors((inputErrors: any) => ({
      ...inputErrors,
      [key]: '',
    }))
  }
  // [value, setErrors]
  //)
  return { errors, setErrors, performValidation, removeErrorFromInputField }
}
