import React, { useState, useEffect } from 'react'
interface ProgressBarProps {
  maxValue: number
  currentValue: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  maxValue,
  currentValue,
}) => {
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    const percentage = (currentValue / maxValue) * 100
    setProgress(percentage > 100 ? 100 : percentage)
  }, [currentValue, maxValue])

  return (
    <div className="w-full h-[8px] bg-[#393B41]">
      <div
        style={{
          width: `${progress}%`,
        }}
        className="max-h-[10px] h-[100%] bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)]"
      />
    </div>
  )
}

export default ProgressBar
