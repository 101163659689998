import React from 'react'
import { useContext } from 'react'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import ProgressBar from './ProgressBar'
import { SCREEN_NAMES } from './Constants'
export const HeaderLogo = () => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue

  const {
    navigation: { currentScreen },
  } = registrationContext

  return (
    <>
      <div className="bg-black grid w-full max-h-[80px] h-full justify-center">
        <div className="w-52 flex justify-center items-center">
          <img
            src="https://cdn.bfldr.com/LCMQM79M/as/6sjfqt5f64pvfb3v3fqhrqw7/Internal_App_Logos?auto=webp&format=png"
            alt="logo"
            className="place-self-center"
          />
        </div>
      </div>

      {currentScreen === SCREEN_NAMES.EMAIL_SELECTION_SCREEN && (
        <ProgressBar maxValue={100} currentValue={20} />
      )}
      {currentScreen === SCREEN_NAMES.DRIVER_FORM_ONLY && (
        <ProgressBar maxValue={100} currentValue={40} />
      )}
      {currentScreen === SCREEN_NAMES.JUNIOR_DRIVER_FORM && (
        <ProgressBar maxValue={100} currentValue={40} />
      )}
      {currentScreen === SCREEN_NAMES.EMAIL_SELECTION_COMPLETION && (
        <ProgressBar maxValue={100} currentValue={70} />
      )}
      {currentScreen === SCREEN_NAMES.SUCCESS_SCREEN && (
        <ProgressBar maxValue={100} currentValue={100} />
      )}
    </>
  )
}
