import React, {
  useEffect,
  ChangeEventHandler,
  FocusEventHandler,
  useContext,
  useMemo,
} from 'react'
import { IDriver, KeyValuePairOfType } from '../../interfaces/Registration'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import JuniorFormItem from './JuniorFormItem'
import { defaultDriverData, JUNIOR_FORM_ADD_CHILD } from './Constants'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-phone-number-input/style.css'

type Props = {
  driver: IDriver
  juniors: IDriver[]
  editMode: boolean
  isSubmitDisable: boolean
  setJuniors: Function
  setErrors: Function
  addressRef: any
  firstNameRef: any
  lastNameRef: any
  phoneRef: any
  dobRef: any
  signRef: any
  errors: any
  handleDriverFieldChange: Function
  handleBlurInput: Function
  handleOnChangeInput: ChangeEventHandler<HTMLInputElement>
  handleOnChangeSelect: ChangeEventHandler<HTMLSelectElement>
  handleBlurSelect: FocusEventHandler<HTMLSelectElement>
  handleSubmit: Function
  handleChangeDriverFields: (data: Partial<KeyValuePairOfType<IDriver>>) => void
}
const JuniorDriverForm = (props: Props) => {
  const { editMode } = props
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { dispatch, juniors, driver, drivers } = registrationContext

  const addNewJunior = () => {
    const newDriver: IDriver = {
      ...(defaultDriverData as IDriver),
    }

    dispatch({
      type: 'SET_DRIVERS',
      payload: {
        drivers: [...drivers, newDriver],
      },
    })
  }

  useEffect(() => {
    if (editMode) {
      const newDriver: IDriver = {
        ...(defaultDriverData as IDriver),
      }

      dispatch({
        type: 'SET_DRIVERS',
        payload: {
          drivers: [...drivers, newDriver],
        },
      })
    }
  }, [editMode, driver, dispatch, drivers])

  const existingDriverAndNewDriver: IDriver[] = useMemo(
    () => [...juniors, ...drivers],
    [drivers, juniors]
  )

  return (
    <>
      <div className="text-center mb-[30px]">
        <h2 className="text-[20px] md:text-[30px] text-black font-[700]">
          Children Details
        </h2>
        <p className="text-[#777] text-[12px] md:text-[14px] font-[500]">
          Please add the details of the children that will be visiting
        </p>
      </div>
      {existingDriverAndNewDriver && existingDriverAndNewDriver.length === 0 ? (
        <JuniorFormItem key={0} idx={0} driver={defaultDriverData as IDriver} />
      ) : (
        existingDriverAndNewDriver.map((junior, idx) => (
          <JuniorFormItem key={idx} idx={idx} driver={junior} />
        ))
      )}

      {!editMode && (
        <div
          className="mt-[30px] relative flex items-center cursor-pointer w-fit flex justify-between items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-[10px] w-full text-white"
          onClick={addNewJunior}
        >
          <span className="uppercase text-[14px] font-[700]">
            {JUNIOR_FORM_ADD_CHILD}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
          >
            <circle
              cx="14.5"
              cy="14.5"
              r="13.5"
              transform="rotate(-180 14.5 14.5)"
              stroke="white"
              strokeWidth="2"
            />
            <path d="M14.5 19L14.5 9" stroke="white" strokeWidth="2" />
            <path d="M19.5 14L9.5 14" stroke="white" strokeWidth="2" />
          </svg>
        </div>
      )}
    </>
  )
}

export default JuniorDriverForm
