import React from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { IDriver } from '../../interfaces/Registration'

type Props = {
  driver: IDriver
  index: Number
}

const BookingDriversList = (props: Props) => {
  const { driver, index } = props
  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <div className="min-w-min mr-10">
            <div className="bg-gray-100 rounded-full text-center flex items-center justify-center w-10 h-10 text-base font-semibold">
              {+index + 1}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          {driver.first_name} {driver.last_name}
        </div>
        <div className="w-[44px] h-6 flex items-center justify-center">
          <div className="w-5	h-5 rounded-full flex items-center justify-center bg-lime-500	">
            <CheckIcon className="w-4	h-4 text-white" />
          </div>
        </div>
      </div>
    </>
  )
}

export default BookingDriversList
