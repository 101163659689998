import React from 'react'
import { DateTime } from 'luxon'
import { IDriver } from '../../interfaces/Registration'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

type Props = {
  driver: IDriver
  openDriverPreview: Function
}

const DriverListItem = (props: Props) => {
  const { driver, openDriverPreview } = props
  const age = Math.abs(
    Math.ceil(
      DateTime.fromFormat(driver.dob, 'dd-mm-yyyy').diffNow('years').years
    )
  )
  return (
    <>
      <div
        className="pt-3 pr-5 pl-4 pb-3 bg-white border border-slate-200 rounded-lg flex items-center justify-between hover:shadow mb-2.5 cursor-pointer"
        onClick={() => {
          openDriverPreview(driver)
        }}
      >
        <div>
          <div className="text-base leading-5 mb-[5px] sm:text-clip text-ellipsis overflow-hidden truncate sm:w-full w-[90px]">
            {`${driver.first_name} ${driver.last_name}`}
          </div>
          <div className="leading-4 text-sm">{age} Year Old</div>
        </div>
        <div className="w-[24px] h-[24px] bg-[#5B36DF] rounded-full flex items-center justify-center">
          <ChevronRightIcon fill="white" className="w-[16px] h-[16px]" />
        </div>
      </div>
    </>
  )
}

export default DriverListItem
