import React from 'react'

type Props = {
  date: String
  item: any
}

const BookingRaceItems = (props: Props) => {
  const { date, item } = props
  // console.log('booking Item ==> ', item)
  return (
    <div className="mb-2.5 px-5 py-4 flex justify-between items-center border-solid border border-inherit rounded-lg">
      <div className="flex flex-col mr-1.5 text-sm font-medium text-gray-900">
        <div>{item.title}</div>
        <div className="text-gray-600 text-xs">
          {item.variant_title === null
            ? `$${item?.price}`
            : date.includes('Arcade Card')
            ? '$' + item.variant_title
            : date.includes('Booking Protection')
            ? `$${Number(item.price_set.shop_money.amount).toFixed(0)}`
            : item.variant_title}
        </div>
      </div>
      <div className="flex items-right">
        {!item.title.includes('Booking Protection') && (
          <>
            <div className="text-sm font-normal text-gray-500">
              {item.current_quantity}
              {item.title.includes('Pre-Paid Arcade Card')
                ? ' Card'
                : item.title.includes('Gift Card')
                ? ' Gift Card'
                : ' driver'}
              {item.current_quantity !== 1 ? 's' : ''}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default BookingRaceItems
