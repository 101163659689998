const Capitalize = (name: string) => {
  if (!name) {
    return ''
  }

  const words = name.split(' ')
  const capitalizedWords = words.map(word => {
    if (word.includes("'")) {
      const parts = word.split("'")
      return parts
        .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join("'")
    } else if (word.includes('-')) {
      const parts = word.split('-')
      return parts
        .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join('-')
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }
  })

  return capitalizedWords.join(' ')
}

export default Capitalize
