export const SCREEN_NAMES = {
  EMAIL_SELECTION_COMPLETION: 'EMAIL_SELECTION_COMPLETION',
  EMAIL_SELECTION_SCREEN: 'EMAIL_SELECTION_SCREEN',
  DRIVER_LIST: 'DRIVER_LIST',
  DRIVER_PREVIEW_SCREEN: 'DRIVER_PREVIEW_SCREEN',
  DRIVER_FORM_ONLY: 'DRIVER_FORM_ONLY',
  GUARDIAN_DRIVER_FORM_ONLY: 'GUARDIAN_DRIVER_FORM_ONLY',
  JUNIOR_DRIVER_FORM: 'JUNIOR_DRIVER_FORM',
  DRIVER_FORM_COMPLITION: 'DRIVER_FORM_COMPLITION',
  SUCCESS_SCREEN: 'SUCCESS_SCREEN',
}

export const defaultDriverData = {
  email: '',
  email_consent: true,
  first_name: '',
  last_name: '',
  dob: '',
  phone_number: '',
  gender: 'male',
  address: '',
  tcs_agreed: false,
  risk_agreement_agreed: false,
  country: 'AU',
  city_id: 2284,
  city: '',
  zip_code: '',
  guardian_dob: '',
  guardian_first_name: '',
  guardian_last_name: '',
}
export const BACK_BUTTON_LABEL = 'Back'

// Email Screen
export const GENERIC_REGISTRATION_TITLE = 'Register Now'
export const PREVIEW_TITLE = 'Existing Profile'
export const BOOKING_REGISTRATION_TITLE = 'Check In'
export const REGISTRATION_DESC =
  'Create your Hyper Karting profile to register for this race'
export const BOOKING_REGISTRATION_DESC =
  'Create your profile with Hyper Karting'
export const GUARDIAN_REGISTRATION_DESC =
  'Create your Hyper Karting profile to register as a guardian'

export const ADULT_LABEL = 'Adult'
export const ADULT_AGE_DESC = 'Above 16 Years Old'
export const JUNIOR_LABEL = 'Child'
export const JUNIOR_AGE_DESC = 'Under 16 Years Old'
export const EMAIL_ADDRESS_LABEL = "Let's start with your email address:"
export const EMAIL_ADDRESS_LABEL_PREVIEW = 'Email Address:'
export const EMAIL_PAGE_BUTTON_LABEL = 'Continue'

// Existing Profile Screen
export const DRIVER_EXISTING_PROFILE_TITLE = 'Select Existing Profile'
export const CREATE_NEW_USER_LABEL = 'Create New User'

// Driver Form Screen
export const UPDATE_EXISITING_DRIVER_TITLE = 'Update driver details'
export const GUARDIAN_REGISTRATION_TITLE = ' Guardian Details'
export const GUARDIAN_REGISTRATION_JUNIOR_TITLE = ' Add Children Details'
export const UPDATE_NONEXISITING_DRIVER_TITLE = 'Create your account'

// Adult Driver Form Screen
export const ADULT_FIRST_NAME_LABEL = 'First Name'
export const ADULT_LAST_NAME_LABEL = 'Last Name'
export const ADULT_DATE_OF_BIRTH_LABEL = 'Date of Birth'
export const ADULT_MOBILE_PHONE_LABEL = 'Mobile Phone'
export const ADULT_GENDER_LABEL = 'Gender'
export const ADULT_GENDER_MALE = 'Male'
export const ADULT_GENDER_FEMALE = 'Female'
export const ADULT_GENDER_NOTDISCLOSE = 'Prefer not to disclose'
export const ADULT_DEFAULT_GENDER = 'Select'
export const ADULT_ADDRESS_LABEL = 'Address'
export const ADULT_SIGN_LABEL = 'Please Sign Here'
export const ADULT_CLEAR_SIGN_LABEL = 'Clear'
export const ADULT_CREATE_DRIVER_BUTTON_LABEL = 'Create and Add driver'
export const ADULT_UPDATE_DRIVER_BUTTON_LABEL = 'Update Profile'
export const TERMS_CONDITIONS_LABEL = 'Terms and Conditions'
export const TERMS_CONDITIONS_TEXT =
  'To Participate in the activity of karting you must read and sign the following:'
export const TERMS_CONDITIONS_SUBTITLE = 'Risk Warning and Acknowledgement'
export const TERMS_CONDITIONS_AGREED_TEXT_1 =
  "I agree with both Hyper Karting and RaceFacers' "
export const TERMS_CONDITIONS_AGREED_TEXT_2 = ' and Cookies Policy.'
export const RISK_AGREEMENT_AGREED_TEXT =
  'I understand that Karting is an inherently dangerous activity which may cause serious injury or death and I am participating at my own risk.'
export const EMAIL_CONSENT_TEXT =
  'I agree to receive email & sms notifications from Hyper Karting regarding: Discount Codes, Promotional Offers & Newsletter.'

// Success Screen
export const GENERIC_REGISTRATION_COMPLETED_TITLE = 'Registration Completed'
export const BOOKING_REGISTRATION_COMPLETED_TITLE = 'Driver Added!'
export const BOOKING_REGISTRATION_COMPLETED_DESC =
  'Your registration is now complete!'
export const GENERIC_REGISTRATION_COMPLETED_STAFF_SUBTEXT =
  'Show this page to staff at the front desk!'
export const BOOKING_REGISTRATION_COMPLETED_SUBTEXT =
  'Head back to your booking'
export const GENERIC_REGISTRATION_COMPLETED_BUTTON_LABEL =
  'Complete another registration'
export const BOOKING_REGISTRATION_COMPLETED_BUTTON_LABEL = 'Continue'

// Junior Driver Form Screen
export const JUNIOR_CREATE_DRIVER_BUTTON_LABEL = 'Create and Add driver'
export const JUNIOR_UPDATE_DRIVER_BUTTON_LABEL = 'Update Profile'
export const JUNIOR_FIRST_NAME_LABEL = 'First Name'
export const JUNIOR_LAST_NAME_LABEL = 'Last Name'
export const JUNIOR_DOB_LABEL = 'Date of Birth'
export const JUNIOR_GENDER_LABEL = 'Gender'
export const JUNIOR_FORM_ADD_CHILD = 'Add child'
export const JUNIOR_FORM_GUARDIAN_LABEL = 'Guardian Details:'
export const JUNIOR_FORM_GUARDIAN_FIRST_NAME_LABEL = 'First Name'
export const JUNIOR_FORM_GUARDIAN_LAST_NAME_LABEL = 'Last Name'
export const JUNIOR_FORM_GUARDIAN_DOB_LABEL = 'Date of Birth'
export const JUNIOR_FORM_GUARDIAN_PHONE_NO_LABEL = 'Mobile Phone'
export const JUNIOR_FORM_GUARDIAN_ADDRESS_LABEL = 'Address'

// Driver Preview Screen
export const DRIVER_DETAILS_CORRECT_LABEL =
  'Looks like you have visited us before, these are the details:'
export const DRIVER_EDIT_DETAILS_BUTTON_LABRL = 'Edit Driver Details'
export const DRIVER_DETAILS_CORRECT_BUTTON_LABEL =
  'Yes, these details are correct'
export const DRIVER_ADD_TO_RACE_BUTTON_LABEL = 'Add To Race'

// Booking Detail Screen
export const BOOKING_INSTRUCTION_1 =
  'Please ensure all participants complete registration and our waiver before check-in at the venue.'
export const BOOKING_INSTRUCTION_2 =
  'Share this link with other participants so they can complete the registration process:'
export const BOOKING_COPY_LINK = 'Copy Link'
export const BOOKING_REGISTERED_DRIVERS = 'Registered'
export const BOOKING_NO_DRIVERS = 'total Registered'
export const BOOKING_DRIVERS_NO_HEADER = 'No.'
export const BOOKING_DRIVERS_NAME_HEADER = 'Driver Name'
export const BOOKING_DRIVERS_AGE_HEADER = 'Age'
export const BOOKING_DRIVERS_STATUS_HEADER = 'Status'
export const BOOKING_REGISTER_BUTTON_LABEL = 'Register Now'
export const BOOKING_NO_NAME = 'Registration Link'
