import axios from 'axios'
export const getRequest = (route: string, params: any) => {
  return axios({
    url: process.env.REACT_APP_API + route,
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      //Authorization: `Bearer ${localStorage['authenticate-token']}`,
    },
    params,
  })
}
export const postRequest = (route: string, data: any) => {
  return axios({
    url: process.env.REACT_APP_API + route,
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      //Authorization: `Bearer ${localStorage['authenticate-token']}`,
    },
    data,
  })
}
